import { GrowingSession } from '@/api';
import { defineStore } from 'pinia';

export const useGrowingSessionStore = defineStore('growingSessionStore', {
    state: () => ({
        growingSessions: [] as Array<GrowingSession>,
    }),
    getters: {

    },
    actions: {
        loadSensors(sensors: Array<GrowingSession>) {
            this.growingSessions = sensors;
        },
        getGrowingSessionById(sessionId: string) {
            return this.growingSessions.find(data => data.id === sessionId) ?? {} as GrowingSession;
        },
    },
    persist: true
})