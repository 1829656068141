<template v-if="deviceInfo">
  <v-dialog v-model="showDeviceInfoDialog" activator="parent" width="auto">
    <v-card min-width="90vw">
      <v-card-item>
        <v-table>
          <thead>
            <tr>
              <th class="text-left">
                Key
              </th>
              <th class="text-left">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(config, index) in deviceInfo" :key="config[0]">
              <td>{{ config[0] }}</td>
              <td>{{ config[1] }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-card-item>
      <v-card-actions>
        <v-btn block color="primary" @click="showDeviceInfoDialog = false">
          Close Dialog
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
<script setup lang="ts">
import { ref, watch } from 'vue';

import { useUserStore } from '@/store/userStore';


const props = defineProps({
  deviceId: {
    type: String,
    required: true,
  }
})

const showDeviceInfoDialog = ref(false);
const deviceInfo = ref([] as [string, unknown][]);
const userStore = useUserStore();

watch(showDeviceInfoDialog, async (show) => {
  if (show) {
    const res = userStore.devices.filter(d => d.id === props.deviceId)[0];
    deviceInfo.value = Object.entries(res ?? {});
  }

})

</script>
  
<style scoped></style>
  