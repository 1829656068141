<template>
    <v-stepper-window-item>
        <slot/>
        <div class="d-flex justify-space-between">
            <div>
                <v-btn v-if="usePrev" :disabled="!canGoPrev()"
                       :icon="prevIcon"
                       variant="text"
                       @click="canGoPrev() && emit('goBack')"/>
            </div>
            <div>
                <v-btn v-if="useNext" color="primary-light-2"
                       :disabled="!canGoNext()"
                       :icon="nextIcon"
                       variant="text"
                       @click="canGoNext() && emit('goNext')"/>
                <v-btn v-if="useDone" color="primary-light-2"
                       :disabled="!canDone()"
                       :icon="doneIcon"
                       variant="text"
                       @click="canDone() && emit('wizardCompleted')"/>
            </div>
        </div>
    </v-stepper-window-item>
</template>

<script setup lang="ts">
import {ref} from 'vue';

const emit = defineEmits<{
    wizardCompleted: [],
    goNext: [],
    goBack: []
}>()

const props = defineProps({
    nextIcon: {
        type: String,
        default: 'mdi-arrow-right',
    },
    prevIcon: {
        type: String,
        default: 'mdi-arrow-left',
    },
    closeIcon: {
        type: String,
        default: 'mdi-close',
    },
    doneIcon: {
        type: String,
        default: 'mdi-check',
    },
    useNext: {
        type: Boolean,
        default: false,
    },
    usePrev: {
        type: Boolean,
        default: false,
    },
    useClose: {
        type: Boolean,
        default: false,
    },
    useDone: {
        type: Boolean,
        default: false,
    },
    canGoNext: {
        type: Function,
        default: () => true,
    },
    canDone: {
        type: Function,
        default: () => true,
    },
    canGoPrev: {
        type: Function,
        default: () => true,
    },
    currentItemIcon: {
        type: String,
        default: ''
    },
});


</script>
