<template>
  <v-card>
    <v-card-title>Sensor</v-card-title>
    <v-card-text>
      <v-form ref="form" @submit.prevent="updateSensorData">
        <v-text-field v-model="toUpdateSensorData.label" label="Sensor label" required />
        <v-slider v-model="toUpdateSensorData.minMoistureValue" class="mt-4" label="Minimum soil moisture" :max="100"
          :min="0" :step="1" thumb-label="always">
          <!-- <template #append>
            <v-label>
              {{ updatedPlantData.minMoistureValue }}
            </v-label>
          </template> -->
        </v-slider>
        <v-btn color="primary" type="submit" variant="outlined">
          Update
        </v-btn>
      </v-form>
    </v-card-text>



    <v-card-text>
      <v-btn color="error" @click="deleteSensorData">
        <v-icon>mdi-delete</v-icon> Delete sensor
      </v-btn>
    </v-card-text>
  </v-card>
</template>
  
<script setup lang="ts">
import { GrowingSession, UpdateGrowingSessionRequest } from '@/api';
import { growingSessionService } from '@/service';
import { useGrowingSessionStore } from '@/store/sessionsStore';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const store = useGrowingSessionStore();


const props = defineProps({
  growingSession: {
    type: Object as () => GrowingSession,
    required: true,
  },
});

const toUpdateSensorData = ref<UpdateGrowingSessionRequest>({ ...props.growingSession } as UpdateGrowingSessionRequest)

async function updateSensorData() {
  await growingSessionService.updateGrowingSession(props.growingSession.id!, toUpdateSensorData.value);
  const growingSessions = await growingSessionService.getAllGrowingSessions() ?? [];
  store.loadSensors(growingSessions);
}

async function deleteSensorData() {
  await growingSessionService.deleteGrowingSession(props.growingSession.id!);
  toUpdateSensorData.value.label = '';
  const sensorDataList = await growingSessionService.getAllGrowingSessions() ?? [];
  store.loadSensors(sensorDataList);
  router.go(-1);
}


</script>
  
<style scoped></style>@/store/sessionsStore