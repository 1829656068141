<template>
  <span>
    <v-toolbar :density="'compact'">
      <v-tabs v-model="currentTab" bg-color="primary" class="w-100" fixed-tabs>
        <v-tab value="1">
          Notes
        </v-tab>
        <v-tab value="2">
          Timelapse
        </v-tab>
        <v-tab value="3">
          Metrics
        </v-tab>
        <v-tab value="4">
          Settings
        </v-tab>
      </v-tabs>
    </v-toolbar>
    <v-window v-model="currentTab">
      <v-window-item value="1">
        <v-list density="compact" style="background-color: transparent;">
            <v-list-item>
                <template #prepend>
                    <v-icon>
                    mdi-watering-can-outline
                </v-icon>
                </template>
                <v-card variant="text">
                    <v-card-title>Last watering</v-card-title>
                    <v-card-subtitle>{{ 'NA' }}</v-card-subtitle>
                </v-card>
            </v-list-item>
          <v-list-item v-if="session.plantSpecies">
            <template #prepend>
              <v-icon v-if="session.plantSpecies">mdi-flower-tulip-outline</v-icon>
            </template>
            <v-list-item-title>Plant species</v-list-item-title>
            <v-list-item-action>
              <v-label>
                {{ session.plantSpecies }}
              </v-label>
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="session.substrates.length > 0">
            <template #prepend>
              <v-icon v-if="session.substrates.length > 0">mdi-sprout-outline</v-icon>
            </template>
            <v-list-item-title>Substrate</v-list-item-title>
            <v-list-item-action>
              <template v-for="(substrate, index2) in session.substrates" :key="index2">
                <v-label class="mr-2">
                  {{ substrate.toLowerCase() }}
                </v-label>
              </template>
            </v-list-item-action>

          </v-list-item>

          <v-list-item
              v-for="(device, index1) in userStore.devices.filter(d => session.sensors.map(s => s.deviceId).includes(d.id))"
              :key="index1" lines="two">
            <template #prepend>
              <v-icon>mdi-network-outline</v-icon>
            </template>
            <v-list-item-title>{{ device.description }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ session.sensors.filter(s => s.deviceId === device.id).map(s => s.type).join(', ').toLowerCase() }}
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-window-item>
      <v-window-item value="2">
        <v-container class="px-2" elevation="0">
          <v-timeline density="compact" direction="vertical" truncate-line="start">
            <v-timeline-item v-for="(timelapseImage, index) in timelapse" :key="timelapseImage.name" density="compact"
                             dot-color="white" size="small" transition="scroll-x-transition" width="100%">
              <template #icon>
                <p/>
              </template>
              <v-card>
                <v-card-title class="d-flex align-center">
                  <v-icon class="mr-2" icon="mdi-camera-outline" size="small"/>
                  <p>{{ format(new Date(timelapseImage.updatedAt), 'dd MMM yy') }}</p>
                </v-card-title>
                <v-img :src="timelapseImage.dataBase64"/>
              </v-card>
            </v-timeline-item>
          </v-timeline>
        </v-container>
      </v-window-item>
      <v-window-item value="3">
        <metrics-tab :growing-session="session"/>
      </v-window-item>
      <v-window-item value="4">
        <update-g-session-form :growing-session="session"/>
      </v-window-item>

    </v-window></span>
</template>

<script setup lang="ts">
import {SensorType, TimelapseImage} from '@/api';
import {useGrowingSessionStore} from '@/store/sessionsStore';
import {useTimelapseStore} from '@/store/timelapseStore';
import {useUserStore} from '@/store/userStore';
import {useViewParamStore} from '@/store/viewParamStore';
import MetricsTab from '@/views/diary/components/metrics/MetricsTab.vue';
import {format} from 'date-fns';
import {onMounted, ref} from 'vue';
import UpdateGSessionForm from './components/UpdateGSessionForm.vue';

const {props} = useViewParamStore();

const timelapse = ref([] as TimelapseImage[]);
const sessionStore = useGrowingSessionStore();
const userStore = useUserStore();
const session = sessionStore.getGrowingSessionById(props.growingSession.id);

let currentTab = ref('1');

onMounted(async () => {
    timelapse.value = await useTimelapseStore().getTimelapse(session.id) ?? [];
})

function getSensorIcon(sensorType: SensorType) {
    // You can map your sensor types to specific icons here
    switch (sensorType) {
        case SensorType.Temperature:
            return 'mdi-thermometer';
        case SensorType.Humidity:
            return 'mdi-water-percent';
        case SensorType.SoilMoisture:
            return 'mdi-water';
        case SensorType.LightIntensity:
            return 'mdi-white-balance-sunny';
        case SensorType.Co2:
            return 'mdi-molecule-co2';
        case SensorType.Pressure:
            return 'mdi-gauge';
        // Add more cases for other sensor types
        default:
            return 'mdi-help';
    }
}
</script>

<style scoped></style>@/store/sessionsStore
