<template>
  <v-card class="mx-auto" variant="flat" max-height="600" max-width="500" min-width="280">
    <v-card-title v-if="props.title" class="text-h6 text-md-h5 text-lg-h4">
      {{ props.title }}
    </v-card-title>
    <v-col>
      <cropper v-if="isSelecting" ref="cropperRef" :canvas="{ height: 500, width: 500, }" image-restriction="stencil"
        :src="img" :stencil-props="{ handlers: {}, movable: false, resizable: false }"
        :stencil-size="{ width: 500, height: 500, }" />
      <v-img v-if="!isSelecting" :src="img" />
    </v-col>

    <v-card-actions>
      <v-btn color="primary" :loading="isLoading" @click="triggerFileImport">
        <v-icon>mdi-camera-plus-outline</v-icon>
      </v-btn>
      <input ref="uploaderRef" accept="image/*" class="d-none" type="file" @change="loadImageInCropper">
      <v-spacer />
      <v-btn color="primary" :disabled="!isSelecting" @click="confirmImage">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

import defaultImage from '@/assets/plant-image-placeholder.jpg'; // => or relative path

const props = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  disablePrev: {
    type: Boolean,
    default: false,
  },
});


const emit = defineEmits<{
  imageAcquired: [imageBase64: string]
}>()

const uploaderRef = ref<HTMLElement>();
const cropperRef = ref<typeof Cropper>();
const defaultImg = ref(defaultImage);
const img = ref(defaultImage);
const isSelecting = ref(false);
const isLoading = ref(false);


async function confirmImage() {
  if (img.value === defaultImg.value) {
    return;
  }
  const cropper = cropperRef.value?.getResult() as typeof Cropper;
  const canvas = cropper.canvas as HTMLCanvasElement;
  const croppedImage = canvas.toDataURL('image/jpeg', 1);
  emit('imageAcquired', croppedImage);
  isSelecting.value = false;
  img.value = croppedImage;
}

function loadImageInCropper(event: Event) {
  const inputElement = event.target as HTMLInputElement;
  const file = inputElement.files?.[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      img.value = e.target?.result as string;
    };
    reader.readAsDataURL(file);
  }
  isLoading.value = false;
}

function triggerFileImport() {
  isSelecting.value = true;
  isLoading.value = true;

  // After obtaining the focus when closing the FilePicker, return the button state to normal
  window.addEventListener('focus', () => {
  }, { once: true });

  // Trigger click on the FileInput
  uploaderRef?.value?.click();

}

</script>

<style scoped>
.cropper {
  height: 200px;
  width: 100%;
  background: #ddd;
}

.file-input-label {
  display: inline-block;
  padding: 8px 16px;
  background-color: #f5f5f5;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
}

.disable-events {
  pointer-events: none
}
</style>
