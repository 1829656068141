import DeviceListToolbar from '@/views/device/DeviceListToolbar.vue';
import DeviceListView from '@/views/device/DeviceListView.vue';
import GSessionDiaryView from '@/views/diary/GSessionDiaryView.vue';

import LoginView from '@/views/LoginView.vue';
import DashboardToolbar from '@/views/dashboard/DashboardToolbar.vue';
import DashboardView from '@/views/dashboard/DashboardView.vue';
import ActionsView from '@/views/wizard/ActionsView.vue';
import {RouteRecordRaw, createRouter, createWebHistory} from 'vue-router';
import NewDiaryWizard from '@/views/wizard/NewDiaryWizard.vue';
import PairDeviceWizard from '@/views/wizard/PairDeviceWizard.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/pair-device',
        name: 'pair-device',
        components: {
            default: PairDeviceWizard,
        },
        meta: {
            pageTitle: 'Device',
            pageIcon: 'mdi-plus-network',
            hideBackButton: false
        }
    },
    {
        path: '/new-diary',
        name: 'new-diary',
        components: {
            default: NewDiaryWizard,
        },
        meta: {
            pageTitle: 'Diary',
            pageIcon: 'mdi-plus',
            hideBackButton: false
        }
    },
    {
        path: '/actions',
        name: 'actions',
        components: {
            default: ActionsView,
        },
        meta: {
            pageTitle: 'Actions',
            pageIcon: 'mdi-shape-square-rounded-plus',
            hideBackButton: false
        }
    },
    {
        path: '/session/diary',
        name: 'growing-session-diary',
        components: {
            default: GSessionDiaryView,
        },
        meta: {
            pageTitle: 'Diary',
            pageIcon: 'mdi-book-open-outline',
            hideBackButton: false
        }
    },
    {
        path: '/device/settings',
        name: 'device-list',
        components: {
            default: DeviceListView,
            toolbar: DeviceListToolbar
        },
        meta: {
            pageTitle: 'Devices',
            pageIcon: 'mdi-network',
            hideBackButton: false
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        components: {
            default: DashboardView,
            toolbar: DashboardToolbar
        },
        meta: {
            pageTitle: 'Dashboard',
            pageIcon: 'mdi-view-dashboard',
            hideBackButton: true
        }
    },
    {
        path: '/',
        name: 'login',
        component: LoginView,
        meta: {
            pageTitle: 'Login',
            hideBackButton: true
        }
    }
];

const router = createRouter({
    history: createWebHistory('/'),
    routes,

    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        return {top: 0}
    }
});

export default router;
