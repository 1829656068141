import { BaseAPI } from '@/api/base';
import BaseService from '@/service/baseService';
import { defineStore } from 'pinia';

export const useApiStore = defineStore('apiStore', {
    state: () => ({
        _geaApiToken: '',
        basePath: import.meta.env.VITE_BASE_URL ?? `${window.location.protocol}//${window.location.hostname}`,
        listeners: [] as BaseService<BaseAPI>[]
    }),
    getters: {
        geaApiToken(state) {
            return state._geaApiToken;
        },
        tokenExists(state){
            return !!state._geaApiToken
        }
    },
    actions: {
        setGeaApiToken(geaApiToken: string) {
            this._geaApiToken = geaApiToken;
            this.updateListeners();
        },
        addListener(service: BaseService<BaseAPI>) {
            this.listeners.push(service);
        },
        updateListeners() {
            this.listeners.map(l => {
                try {
                    l.updateConfig();
                } catch (e) {
                    //
                }
            });
        }
    },
    persist: true

})
