import { Configuration } from '@/api';
import { BaseAPI } from '@/api/base';
import router from '@/plugins/router';
import { useApiStore } from '@/store/apiStore';
import { useSnackbarStore } from '@/store/snackbarStore';
import { AxiosError, HttpStatusCode } from 'axios';

export default abstract class BaseService<T extends BaseAPI> {

    protected api: T;
    constructor(api: T) {
        this.api = api;
        const apiStore = useApiStore();
        apiStore.addListener(this);
    }

    protected static getConfig() {
        const apiStore = useApiStore();
        return new Configuration({
            basePath: apiStore.basePath,
            baseOptions: { headers: { 'Authorization': apiStore.geaApiToken } }
        });
    }

    public updateConfig() {
        this.api['configuration'] = BaseService.getConfig();
    }


    handleError(err: any, suppresSnackbar = false) {
        console.error(err);

        if (err instanceof AxiosError) {
            const errorBody = err.response?.data;
            if (!suppresSnackbar) {
                const { showError } = useSnackbarStore()
                showError(JSON.stringify(errorBody));
            }

            if (err.response?.status === HttpStatusCode.Unauthorized) {
                router.replace({ name: 'login' });
            }
        }
    }
}
