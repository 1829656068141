<template>
    <v-stepper v-model="currentStep" hide-actions :non-linear="false"
               style=""
    >
        <v-stepper-header style="max-height: 55px;">
            <v-stepper-item
                :complete="currentStep > 0"
                icon="mdi-label"
            />
            <v-divider/>
            <v-stepper-item
                :complete="currentStep > 1"
                icon="mdi-camera"
            />
            <v-divider/>
            <v-stepper-item
                v-if="userStore.devices.length > 0"
                :complete="currentStep > 2"
                icon="mdi-network"
            />
            <v-divider v-if="userStore.devices.length > 0"/>
            <v-stepper-item
                :complete="currentStep > 3"
                icon="mdi-sprout"

            />
        </v-stepper-header>
        <v-stepper-window>
            <wizard-item :can-go-next="() => createSessionReq.label?.length > 0"
                         use-close use-next
                         @go-next="currentStep++">
                <v-form ref="form" class="pt-3">
                    <v-text-field v-model="createSessionReq.label" aria-required="true" label="Diary name" required
                                  variant="outlined"/>
                    <v-text-field v-model="createSessionReq.position.room" aria-required="true" label="Room" required
                                  variant="outlined"/>
                    <v-autocomplete v-model="createSessionReq.plantSpecies" :items="plantSpeciesAutocomplete"
                                    label="Plant species"
                                    no-data-text="" variant="outlined"
                                    @update:search="autocompletePlantSpecies"
                    />
                    <!-- <v-text-field v-model="createSessionReq.seedingDate" label="Plant age" variant="outlined" /> -->
                </v-form>
            </wizard-item>
            <wizard-item use-next use-prev
                         @go-back="currentStep--"
                         @go-next="currentStep++">
                <acquire-image-card class="mb-4" @image-acquired="img => { handleImageAcquired(img) }"/>
            </wizard-item>
            <wizard-item v-if="userStore.devices.length > 0"
                         use-next use-prev
                         @go-back="currentStep--"
                         @go-next="currentStep++">
                <v-select v-model="selectedDeviceId" :item-title="nameof<Device>(d => d.description)"
                          :item-value="nameof<Device>(d => d.id)" :items="userStore.devices"
                          label="Select a device"/>

                <v-select v-if="soilMoistureSensors.length > 0" v-model="selectedSoilMoistureSensor"
                          :item-title="nameof<Sensor>(s => s.code)" :items="soilMoistureSensors"
                          label="Select moisture sensor"/>
                <div v-if="soilMoistureSensors.length > 0" class="px-3">
                    <v-label class="text-h8">
                        Minimum soil moisture
                    </v-label>
                    <v-slider v-model="createSessionReq.minMoistureValue" :max="100" :min="0" :step="1" thumb-label/>
                </div>
                <v-select v-model="selectedLightIntensitySensor" :item-title="nameof<Sensor>(s => s.code)"
                          :items="lightIntensitySensors"
                          label="Select light sensor"/>

                <v-select v-model="selectedTemperatureSensor" :item-title="nameof<Sensor>(s => s.code)"
                          :items="temperatureSensors"
                          label="Select temperature sensor"/>

                <v-select v-model="selectedHumiditySensor" :item-title="nameof<Sensor>(s => s.code)"
                          :items="humiditySensors"
                          label="Select humidity sensor"/>
            </wizard-item>
            <wizard-item use-done use-prev
                         @go-back="currentStep--"
                         @wizard-completed="completeWizard"
            >
                <v-autocomplete
                    v-model="createSessionReq.substrates"
                    :items="['Peat moss','Coconut coir', 'Perlite', 'Vermiculite','Expanded clay pebbles', 'Rockwool',
                    'Potting soil', 'Water', 'Sand']"
                    label="Substrate composition"
                    multiple
                />

                <v-autocomplete v-model="selectedPosition"
                                :append-icon="'mdi-crosshairs-gps'"
                                :items="positionAutocomplete" label="Location"
                                no-data-text=""
                                variant="outlined"
                                @update:search="autocompletePosition"
                />
            </wizard-item>
        </v-stepper-window>
    </v-stepper>
</template>

<script setup lang="ts">
import {Device, LocationResponse, Sensor, SensorType} from '@/api';
import {CreateGrowingSessionRequest} from '@/api/api';
import {growingSessionService, plantSpeciesService, geolocationService} from '@/service';
import {useUserStore} from '@/store/userStore';
import AcquireImageCard from '@/views/components/AcquireImageCard.vue';
import {nameof} from 'ts-simple-nameof';
import {ref, watch} from 'vue';
import {VSelect} from 'vuetify/lib/components/index.mjs';
import WizardItem from './components/WizardItem.vue';
import {useSnackbarStore} from '@/store/snackbarStore';
import router from '@/plugins/router';

const userStore = useUserStore();
const snackbarStore = useSnackbarStore();


const currentStep = ref(0);

const selectedDeviceId = ref<string>();
//selectedDeviceId.value = userStore.devices[0].id;

const selectedSoilMoistureSensor = ref<Sensor>();
const soilMoistureSensors = ref<Sensor[]>([]);
const selectedLightIntensitySensor = ref<Sensor>();
const lightIntensitySensors = ref<Sensor[]>([]);
const selectedTemperatureSensor = ref<Sensor>();
const temperatureSensors = ref<Sensor[]>([]);
const selectedHumiditySensor = ref<Sensor>();
const humiditySensors = ref<Sensor[]>([]);

const plantSpeciesAutocomplete = ref<string[]>([]);
const positionAutocomplete = ref<string[] | undefined>([]);
const selectedPosition = ref<string | undefined>();
let positions: Set<LocationResponse> = new Set();
let autocompleteDebounce: string | number | NodeJS.Timeout | undefined;

const createSessionReq = ref({
    label: '',
    position: {},
    sensors: [],
    minMoistureValue: 0,
    substrates: []
} as CreateGrowingSessionRequest);


watch(() => createSessionReq.value.plantSpecies, (plantSpecies) => {
    if (!plantSpecies) {
        return;
    }
    console.log('getAutocomplete', plantSpecies);

    // cancel pending call
    clearTimeout(autocompleteDebounce);

    // delay new call 500ms
    autocompleteDebounce = setTimeout(async () => {
        plantSpeciesAutocomplete.value = (await plantSpeciesService.getAutocomplete(plantSpecies!))!
    }, 350);

})

async function autocompletePlantSpecies(plantSpecies: string) {
    if (!plantSpecies) {
        return;
    }
    // cancel pending call
    clearTimeout(autocompleteDebounce);

    // delay new call 500ms
    autocompleteDebounce = setTimeout(async () => {
        const res = (await plantSpeciesService.getAutocomplete(plantSpecies!))!;
        if (res.length === 0) {
            createSessionReq.value.plantSpecies = plantSpecies;
        }
        plantSpeciesAutocomplete.value = res;
    }, 350);
}

async function autocompletePosition(query: string) {
    if (!query || query.length < 2) {
        return;
    }
    // cancel pending call
    clearTimeout(autocompleteDebounce);

    // delay new call 500ms
    autocompleteDebounce = setTimeout(async () => {
        const res = await geolocationService.search(query!);
        res!.forEach(pos => positions.add(pos));
        positionAutocomplete.value = res!.map(i => i.displayName!);
        console.log(positionAutocomplete.value)
    }, 350);
}


watch(selectedDeviceId, (deviceId) => {
    if (!deviceId) {
        return;
    }
    soilMoistureSensors.value = userStore.getSensorsByType(deviceId, SensorType.SoilMoisture);
    selectedSoilMoistureSensor.value = soilMoistureSensors.value[0];

    lightIntensitySensors.value = userStore.getSensorsByType(deviceId, SensorType.LightIntensity);
    selectedLightIntensitySensor.value = lightIntensitySensors.value[0];

    temperatureSensors.value = userStore.getSensorsByType(deviceId, SensorType.Temperature);
    selectedTemperatureSensor.value = temperatureSensors.value[0];

    humiditySensors.value = userStore.getSensorsByType(deviceId, SensorType.Humidity);
    selectedHumiditySensor.value = humiditySensors.value[0];

})


async function handleImageAcquired(image: string) {
    createSessionReq.value.plantImageBase64 = image;
}

async function getGeolocation() {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                createSessionReq.value.position!.latitude = position.coords.latitude! + '';
                createSessionReq.value.position!.longitude = position.coords.longitude! + '';
                geolocationService.reverse(position.coords.latitude, position.coords.longitude).then(value => {
                    createSessionReq.value.position!.displayName = value!.displayName;

                })
            },
            (error) => {
                snackbarStore.showError(`Can't get geolocation:', ${error.message}`)
            }
        );
    } else {
        snackbarStore.showError('Geolocation is not supported by this browser.')
    }
}

async function completeWizard() {
    selectedHumiditySensor.value && createSessionReq.value.sensors.push(selectedHumiditySensor.value)
    selectedSoilMoistureSensor.value && createSessionReq.value.sensors.push(selectedSoilMoistureSensor.value)
    selectedLightIntensitySensor.value && createSessionReq.value.sensors.push(selectedLightIntensitySensor.value)
    selectedTemperatureSensor.value && createSessionReq.value.sensors.push(selectedTemperatureSensor.value)

    const position = [...positions].filter(p => {
        console.log(p)
        return p.displayName === selectedPosition.value
    })[0];

    if (position) {
        createSessionReq.value.position.displayName = position.displayName;
        createSessionReq.value.position.town = position.address?.village ?? position.address?.town;
        createSessionReq.value.position.city = position.address?.city;
        createSessionReq.value.position.state = position.address?.state;
        createSessionReq.value.position.country = position.address?.country;
        createSessionReq.value.position.latitude = position.lat!;
        createSessionReq.value.position.longitude = position.lon!;

    }
    await growingSessionService.createGrowingSession(createSessionReq.value);
    await router.push({name: 'dashboard'});
}

</script>
<style>
.v-stepper {
    background-color: transparent;
}

.v-stepper.v-sheet {
    box-shadow: none;
    border-radius: 4px;
    overflow: hidden;
}

</style>
