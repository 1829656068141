<template>
  <v-snackbar v-model="showSnackbar" :timeout="2000">
    {{ message }}
    <template #actions>
      <v-btn variant="text" @click="showSnackbar = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup lang="ts">
import { useSnackbarStore } from '@/store/snackbarStore';
import { storeToRefs } from 'pinia';
import { watch } from 'vue';

const store = useSnackbarStore();
const { message, showSnackbar } = storeToRefs(store); // state and getters need "storeToRefs"

watch(() => message.value, (newMessage) => {
  console.log(`Show snackbar message: ${newMessage}`)
})


</script>

<style scoped></style>