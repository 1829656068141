import { SignInRequest, SignUpRequest, UserApi } from '@/api';
import { useApiStore } from '@/store/apiStore';
import BaseService from './baseService';

export default class UserService extends BaseService<UserApi> {

    constructor() {
        super(new UserApi(BaseService.getConfig()));
    }


    public async signIn(req: SignInRequest) {
        try {
            localStorage.clear();
            while (localStorage.length > 0) {
                console.log('Waiting localStorage clear');
            }
            const res = await this.api.signIn(req);
            const apiStore = useApiStore();
            apiStore.setGeaApiToken(`Bearer ${res.headers['gea-api-token']}`);
            return { user: res.data, geaApiToken: `Bearer ${res.headers['gea-api-token']}` };
        } catch (e) {
            this.handleError(e)
        }

    }

    public async signUp(req: SignUpRequest) {
        try {
            const res = await this.api.signUp(req);
            return res.data;
        } catch (e) {
            this.handleError(e)
        }
    }

}
