/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import { createVuetify } from 'vuetify'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  components: {
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        dark: false,
        colors: {
          'primary': '#1B5E20',
          'primary-light-1': '#388E3C',
          'primary-light-2': '#4CAF50',
          'primary-light-3': '#81C784',
          'secondary': '#039BE5',
          'secondary-light-1': '#29B6F6',
          'secondary-light-2': '#81D4FA',
          'secondary-light-3': '#E1F5FE',
          background: '#f7fff6',
          surface: '#FFFFFF',
          icon: '#000000',
          error: '#B00020',
          info: '#2196F3',
          success: '#4CAF50',
          warning: '#FB8C00',
        }
      }
    }
  },

})
