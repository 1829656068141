<template>
  <v-app style=" overflow: hidden !important;">
    <app-toolbar />
    <v-main>
      <app-snackbar />
      <router-view v-slot="{ Component, route }">
        <transition name="fade">
          <component :is="Component" :key="route.path" />
        </transition>
      </router-view>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">

import { onMounted } from 'vue';
import { useApiStore } from './store/apiStore';
import AppSnackbar from './views/components/AppSnackbar.vue';
import AppToolbar from './views/components/AppToolbar.vue';
import router from '@/plugins/router';

const apiStore = useApiStore();
onMounted(() => {
  apiStore.updateListeners();
  if(apiStore.tokenExists){
      router.replace({
          name: 'dashboard'
      })
  }
})
</script>


<style scoped></style>
