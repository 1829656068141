<template>
  <v-container class="h-100" fluid>
    <v-row class="h-100">
      <v-col class="d-flex justify-center" cols="12" :lg="6" :md="6" :sm="6" :xl="6" :xs="12" :xxl="6" :xxxl="6">
        <v-img max-width="350" :src="geaBannerLogin" />
      </v-col>
      <v-col class="d-flex justify-center align-center" cols="12" :lg="6" :md="6" :sm="6" :xl="6" :xs="12" :xxl="6"
        :xxxl="6">
        <v-container fluid>
          <v-row no-gutter>
            <v-col>
              <v-card-title>Login</v-card-title>
            </v-col>
          </v-row>
          <v-row no-gutter>
            <v-col>
              <v-text-field v-model="email" label="Email" name="email" placeholder="user@example.com" required
                :rules="usernameRules" type="text" />
            </v-col>
          </v-row>
          <v-row no-gutter>
            <v-col cols="12">
              <v-text-field v-model="password" label="Password" name="password" placeholder="password" required
                :rules="passwordRules" type="password" />
            </v-col>
            <v-col v-if="isRegister" cols="12">
              <v-text-field v-model="confirmPassword" label="Confirm Password" name="confirmPassword"
                placeholder="confirm password" required :rules="confirmPasswordRules" type="password" />
            </v-col>
            <v-col cols="12">
              <v-btn block class="mt-4" color="primary" type="submit" value="login"
                @click="isRegister ? register() : login()" @keyup.enter="isRegister ? register() : login()">
                {{ isRegister ? stateObj.register.name : stateObj.login.name }}
              </v-btn>
              <div class="text-grey mt-4" @click="isRegister = !isRegister;">
                {{ toggleMessage }}
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">

import { SignInRequest, SignUpRequest } from '@/api';
import geaBannerLogin from '@/assets/gea-banner-login.jpg';
import { userService } from '@/service';
import { useUserStore } from '@/store/userStore';
import { computed, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';


const router = useRouter();

const userStore = useUserStore();
const email = ref('');
const password = ref('');
const confirmPassword = ref('');
let isRegister = ref(false);
let errorMessage = ref('');

const usernameRules = ref([
  (v: string) => !!v || 'Username is required',
  (v: string) => /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'Insert a valid email',
])
const passwordRules = ref([
  (v: string) => !!v || 'Password is required',
  (v: string) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}/.test(v) || 'Password must contain at least lowercase letter, one number, a special character and one uppercase letter',
])
const confirmPasswordRules = ref([
  (v: string) => v === password.value || 'Password didn\'t match',
])

const stateObj = ref({
  register: {
    name: 'Register',
    message: 'Aleady have an Acoount? login.'
  },
  login: {
    name: 'Login',
    message: 'Register'
  }
})

onMounted(() => {
  localStorage.clear();
  while (localStorage.length > 0) {
    console.log('Waiting localStorage clear');
  }
})

async function login() {

  const res = await userService.signIn({ email: email.value, password: password.value } as SignInRequest)
  if (!res) {
    return;
  }
  console.log('Login successful');
  await userStore.loadUserSession(res.user);
  await router.replace({ name: 'dashboard' });
}

async function register() {
  if (!(password.value === confirmPassword.value)) {
    errorMessage.value = 'Password did not match';
    return;
  }
  const response = await userService.signUp({ email: email.value, password: password.value } as SignUpRequest);
  if (response) {
    // Handle successful registration
    // Redirect to dashboard or perform any other actions
    console.log(response);
    isRegister.value = false;
  }
}

const toggleMessage = computed(() => {
  return isRegister.value ? stateObj.value.register.message : stateObj.value.login.message
})
</script>

