import {DeviceApi, DeviceCommandEnum, GeolocationApi} from '@/api';
import BaseService from './baseService';

export default class GeolocationService extends BaseService<GeolocationApi> {

    constructor() {
        super(new GeolocationApi());
    }

    public async search(query: string) {
        try {
            return (await this.api.search(query)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

    public async reverse(latitude: number, longitude: number) {
        try {
            return (await this.api.reverse(latitude, longitude)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

}
