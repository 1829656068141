<template>
  <v-btn icon @click="navigateToManager">
    <v-icon>
      mdi-shape-square-rounded-plus
    </v-icon>
  </v-btn>
  <notification-list />
</template>

<script setup lang="ts">
import NotificationList from '@/views/dashboard/components/NotificationList.vue';
import { useRouter } from 'vue-router';


const router = useRouter();

function navigateToManager() {
  router.push({ name: 'actions' });
}

function navigateToDeviceManager() {
  router.push({ name: 'device-list' });
}


</script>

<style scoped></style>
