<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<template>
    <v-card-text>
        <v-expand-transition>
            <v-card-subtitle v-if="showDetails">
                <v-row v-if="session.sensors.filter(s => s.type === SensorType.SoilMoisture)" no-gutters>
                    <v-col class="px-0" cols="1">
                        <v-label>
                            <v-icon>{{ moistureBarIcon }}</v-icon>
                        </v-label>
                    </v-col>
                    <v-col class="pl-0" cols="11">
                        <v-progress-linear ref="moistureBar" class="elevation-1 rounded mt-2" :color="moistureBarColor"
                                           height="7"
                                           max="100" :model-value="sensorReadings!.moisture" />
                    </v-col>
                </v-row>
                <v-row v-if="session.sensors.filter(s => s.type === SensorType.SoilMoisture)" no-gutters>
                    <v-col class="px-0" cols="1">
                        <v-label>
                            <v-icon>{{ moistureBarIcon }}</v-icon>
                        </v-label>
                    </v-col>
                    <v-col class="pl-0" cols="11">
                        <v-progress-linear ref="moistureBar" class="elevation-1 rounded mt-2" :color="moistureBarColor"
                                           height="7"
                                           max="100" :model-value="sensorReadings!.moisture" />
                    </v-col>
                </v-row>
                <v-row no-gutter>
                    <v-col v-if="session.sensors.filter(s => s.type === SensorType.Temperature)" class="px-0">
                        <v-chip prepend-icon="mdi-thermometer" variant="text">
                            T {{ sensorReadings.temperature.toFixed(1) }}°C
                        </v-chip>
                    </v-col>
                    <v-col v-if="session.sensors.filter(s => s.type === SensorType.Humidity)" class="px-0">
                        <v-chip prepend-icon="mdi-water-percent" variant="text">
                            RH {{ sensorReadings.humidity.toFixed(0) }}%
                        </v-chip>
                    </v-col>
                </v-row>
            </v-card-subtitle>
        </v-expand-transition>
    </v-card-text>
</template>

<script setup lang="ts">
import {GrowingSession, SensorsReading, SensorType} from '@/api';
import {ref, watch} from 'vue';
import {useRouter} from 'vue-router';
import {VProgressLinear} from 'vuetify/lib/components/index.mjs';

const router = useRouter();

const props = defineProps({
    session: {
        type: Object as () => GrowingSession,
        required: true,
    },
    sensorReadings: {
        type: Object as () => SensorsReading,
        required: true,
    },
    showDetails: {
        type: Boolean,
        required: true
    }
});

const moistureBar = ref<VProgressLinear>();
const moistureBarColor = ref('secondary-light-1');
const moistureBarIcon = ref('mdi-water');

function calculateMoistureBarColor() {
    // Validate the input value to ensure it falls within the min-max range

    const currentMoisture = props.sensorReadings?.moisture!;
    const minThreshold = props.session.minMoistureValue!;

    const maxThreshold = 70;

    if (currentMoisture < (minThreshold + 15)) {
        moistureBarColor.value = 'orange';
        moistureBarIcon.value = 'mdi-water-alert';
    }
    if (currentMoisture < minThreshold) {
        moistureBarColor.value = 'red';
        moistureBarIcon.value = 'mdi-water-remove';

    }
    if (currentMoisture > maxThreshold) {
        moistureBarColor.value = 'secondary';
    }
}

watch(() => moistureBar.value, () => {
    calculateMoistureBarColor();

})

</script>

<style scoped></style>
