<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<template>
    <v-btn :color="notifications.findLast(e => e.toRead) ? 'red' : 'grey'" icon>
        <v-icon>mdi-bell-badge-outline</v-icon>
        <v-menu activator="parent" :close-on-content-click="false" @update:model-value="markNotificationsAsRead">
            <v-list>
                <v-list-item v-for="(notification, i) in notifications" :key="notification.id"
                    :disabled="!notification.toRead">
                    <template #prepend>
                        <v-avatar color="grey-lighten-1">
                            <v-icon color="white">
                                mdi-watering-can-outline
                            </v-icon>
                        </v-avatar>
                    </template>
                    <v-list-item-title>
                        It's time to water!
                    </v-list-item-title>
                    <v-list-item-subtitle>
                        Soil moisture level is below x%
                    </v-list-item-subtitle>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-btn>
</template>
  
<script setup lang="ts">
import { Notification } from '@/api';
import { notificationService } from '@/service';
import { useUserStore } from '@/store/userStore';
import { ref } from 'vue';


const userStore = useUserStore();

const notifications = ref<Notification[]>([]);

async function markNotificationsAsRead(value: boolean) {
    if (!value && notifications.value) {
        notifications.value.forEach(e => e.toRead = false);
        await notificationService.markNotificationsAsRead(notifications.value.map(e => e.id!));
    }

}

// onMounted(async () => {
//     const moistureWarning = userStore.getNotificationsByType<IMoistureWarning>(NotificationType.SOIL_MOISTURE_WARNING)
//         .find(elem => elem.params.sensor === props.sensor.id);

// })
</script>
  
<style></style>
  