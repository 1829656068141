import { MetricsApi, SensorType } from '@/api';
import BaseService from './baseService';

export default class MetricsService extends BaseService<MetricsApi> {

    constructor() {
        super(new MetricsApi());
    }


    public async getMetrics(sensorType: SensorType, deviceId: string, sensorCode: number, params: {
        dateFrom: string,
        dateTo: string,
        resolution: string,
    }) {
        try {
            const res = await this.api.getMetrics(deviceId, sensorCode, sensorType,
                params.dateFrom, params.dateTo, params.resolution);
            return res.data.timeSeries;
        } catch (e) {
            this.handleError(e)
        }

    }

}
