import { GrowingSession } from '@/api';
import { defineStore } from 'pinia';

export interface Props {
    growingSession: GrowingSession
}

export const useViewParamStore = defineStore('viewParamStore', {
    state: () => ({
        _props: {} as Props,
        toolbarShowBackButton: true,
    }),
    getters: {
        props(state) {
            return state._props;
        }
    },
    actions: {
        setNextProps(props: Props) {
            this._props = props;
        },
        hideToolbarBackButton() {
            this.toolbarShowBackButton = false;
        },
        showToolbarBackButton() {
            this.toolbarShowBackButton = true;
        }
    },
    persist: true

})
