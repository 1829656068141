import DeviceService from './deviceService';
import GrowingSessionService from './growingSessionService';
import MetricsService from './metricsService';
import NotificationService from './notificationService';
import PlantSpeciesService from './plantSpeciesService';
import TimeapseService from './timelapseService';
import UserService from './userService';
import GeolocationService from '@/service/geolocationService';

export let deviceService: DeviceService;
export let growingSessionService: GrowingSessionService;
export let metricsService: MetricsService;
export let notificationService: NotificationService;
export let timelapseService: TimeapseService;
export let userService: UserService;
export let plantSpeciesService: PlantSpeciesService;
export let geolocationService: GeolocationService;



export function registerServices() {
    deviceService = new DeviceService();
    growingSessionService = new GrowingSessionService();
    metricsService = new MetricsService();
    notificationService = new NotificationService();
    timelapseService = new TimeapseService();
    userService = new UserService();
    plantSpeciesService = new PlantSpeciesService();
    geolocationService = new GeolocationService();

}
