<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-list>
          <v-list-subheader>
            Acquired devices
          </v-list-subheader>
          <v-list-item v-for="(device, index) in devices" :key="device.id">
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-network-outline</v-icon>
              </v-avatar>
            </template>

            <v-list-item-title>{{ device.description }}</v-list-item-title>
            <v-list-item-subtitle>
              {{ device.id }}
            </v-list-item-subtitle>

            <template #append>
              <v-btn class="mr-2" density="compact" variant="outlined">
                Info
                <device-info-dialog :device-id="device.id!" />
              </v-btn>
              <v-btn class="mr-2" density="compact" variant="outlined" @click="startDevicePublisher(device.id!)">
                Start
              </v-btn>
              <v-btn density="compact" variant="outlined" @click="pauseDevicePublisher(device.id!)">
                Pause
              </v-btn>
            </template>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { DeviceCommandEnum } from '@/api';
import { deviceService } from '@/service';
import { useUserStore } from '@/store/userStore';
import DeviceInfoDialog from './dialog/DeviceInfoDialog.vue';

const { devices } = useUserStore();

async function startDevicePublisher(deviceId: string) {

  await deviceService.startPausePublisher(deviceId, DeviceCommandEnum.StartPublisher);
}

async function pauseDevicePublisher(deviceId: string) {
  await deviceService.startPausePublisher(deviceId, DeviceCommandEnum.PausePublisher);
}

</script>

<style scoped></style>


<style scoped></style>