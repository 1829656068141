/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import { App } from 'vue'
import router from './router'
import vuetify from './vuetify'
import { loadFonts } from './webfontloader'

import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'



// Types
const pinia = createPinia()
  .use(piniaPluginPersistedstate);
export function registerPlugins(app: App) {
  loadFonts()
  app
    .use(vuetify)
    .use(router)
    .use(pinia)
}
