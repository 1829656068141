<template>
  <v-card class="ma-2">
    <v-card-title>
      <v-row>
        <v-col cols="7">
          {{ `${growingSession.label} Metrics` }}
        </v-col>
        <v-spacer />
        <v-col cols="6" style="min-width: fit-content;">
          <v-select v-model="metricsStore.currentTimeRange" density="compact"
            :items="Object.keys(metricsStore.timeRanges)" label="Time Range" prepend-inner-icon="mdi-arrow-left-right"
            variant="outlined" />
        </v-col>
        <v-col cols="6" style="min-width: fit-content;">
          <v-select v-model="metricsStore.currentResolution" density="compact"
            :items="metricsStore.timeRanges[metricsStore.currentTimeRange].resolutions" label="Resolution"
            prepend-inner-icon="mdi-arrow-left-right" variant="outlined" />
        </v-col>
      </v-row>
    </v-card-title>

    <v-row>
      <v-col cols="12">
        <metrics-chart v-if="growingSession" :metrics="[
          { sensorType: SensorType.SoilMoisture, color: '#95B46A' },
          { sensorType: SensorType.Temperature, color: '#F4A261' },
          { sensorType: SensorType.Humidity, color: '#037971' }]" :separate-charts="true" :session="growingSession" />
      </v-col>
    </v-row>
  </v-card>
</template>
<script setup lang="ts">
import { GrowingSession, SensorType } from '@/api';
import { useMetricsStore } from '@/store/metricsStore';
import { watch } from 'vue';
import MetricsChart from './components/MetricsChart.vue';

const props = defineProps({
  growingSession: {
    type: Object as () => GrowingSession,
    required: true,
  },
});

const metricsStore = useMetricsStore();

watch(() => props.growingSession, () => {
  console.log(props.growingSession)
})

</script>
  
<style scoped></style>