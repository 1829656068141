import { NotificationApi } from '@/api';
import { useUserStore } from '@/store/userStore';
import BaseService from './baseService';

export default class NotificationService extends BaseService<NotificationApi> {

    constructor() {
        super(new NotificationApi());
    }


    public async getNotifications() {
        try {
            const { user } = useUserStore();
            const res = await this.api.getNotificationsByUser(user.id!);
            return res.data;
        } catch (e) {
            this.handleError(e)
        }

    }
    public async markNotificationsAsRead(notificationIds: string[]) {
        try {
            await this.api.markNotificationsAsRead(notificationIds);
        } catch (e) {
            this.handleError(e)
        }

    }

}
