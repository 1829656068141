import { DeviceApi, DeviceCommandEnum } from '@/api';
import BaseService from './baseService';

export default class DeviceService extends BaseService<DeviceApi> {

    constructor() {
        super(new DeviceApi());
    }

    public async getDevicesByUserId(userId: string) {
        try {
            return (await this.api.getDeviceByUserId(userId)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

    public async getDevicesById(deviceIds: string[]) {
        try {
            return (await this.api.getDevicesById(deviceIds)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

    public async pairDevice(userId: string, deviceId: string, description: string) {
        try {
            return (await this.api.pairDevice(userId, deviceId, description)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

    public async startPausePublisher(deviceId: string, command: DeviceCommandEnum) {
        try {
            return (await this.api.startPausePublisher(deviceId, command)).data;
        } catch (e) {
            this.handleError(e)
        }

    }

}
