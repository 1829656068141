import { defineStore } from 'pinia';

export const useSnackbarStore = defineStore('snackbar', {
    state: () => ({
        message: '',
        showSnackbar: false
    }),
    getters: {
    },
    actions: {
        showError(message: string) {
            this.message = message;
            this.showSnackbar = true;
            setTimeout(() => this.showSnackbar = false, 3 * 1000)
        },
        showSuccess(message: string) {
            this.message = message;
            this.showSnackbar = true;
            setTimeout(() => this.showSnackbar = false, 3 * 1000)
        },
    },
    persist: true

})