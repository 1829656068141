<template>
    <v-app-bar color="primary-light-1" density="compact">
        <v-btn v-if="!route.meta.hideBackButton && store.toolbarShowBackButton" icon @click="router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-app-bar-title>
            <div class="d-flex align-content-center">
                <v-icon v-if="route.meta.pageIcon" class="mr-2">
                    {{ route.meta.pageIcon }}
                </v-icon>
                <span>{{ route.meta.pageTitle }}</span>
            </div>
        </v-app-bar-title>
        <v-spacer/>
        <router-view v-slot="{ Component }" name="toolbar">
            <component :is="Component" :key="route.path"/>
        </router-view>
    </v-app-bar>
</template>

<script setup lang="ts">
import {useViewParamStore} from '@/store/viewParamStore';
import {useRoute, useRouter} from 'vue-router';

const router = useRouter();

const route = useRoute()

const store = useViewParamStore();

</script>

<style scoped></style>
