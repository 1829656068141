import { Device, Notification, NotificationType, SensorType, User } from '@/api';
import { deviceService } from '@/service';
import { defineStore } from 'pinia';
export const useUserStore = defineStore('userStore', {
    state: () => ({
        user: {} as User,
        devices: [] as Device[],
        notifications: [] as Notification[],
    }),
    getters: {
        getDevice(state) {
            return (deviceId: string) => state.devices.filter(d => d.id === deviceId)[0];
        }
    },
    actions: {
        async loadUserSession(user: User) {
            console.log('loadUserSession', user);
            this.user = user;
            this.devices = (await deviceService.getDevicesByUserId(user.id))!;
        },
        addDevice(device: Device) {
            console.log('addDevice', device);
            if (this.devices.find(d => d.id === device.id)) {
                return;
            }
            this.devices.push(device);
        },
        getSensorsByType(deviceId: string, sensorType: SensorType) {
            console.log('getSensorsByType', deviceId, sensorType)
            return this.devices
                .filter(device => device.id === deviceId)
                .map(device => {
                    return device.sensors
                        .filter(sensor => sensor.type === sensorType)
                })
                .flat()
        },
        loadNotifications(notifications: Notification[]) {
            this.notifications = notifications;
        },
        getNotificationsByType(type: NotificationType) {
            return this.notifications.filter(notification => notification.type === type) as Notification[];
        }
    },
    persist: true

})
