import { CreateGrowingSessionRequest, GrowingSessionApi, UpdateGrowingSessionRequest } from '@/api';
import { useUserStore } from '@/store/userStore';
import BaseService from './baseService';

export default class GrowingSessionService extends BaseService<GrowingSessionApi> {

    constructor() {
        super(new GrowingSessionApi());
    }

    public async getGrowingSession(sessionId: string) {
        try {
            const res = await this.api.getGrowingSession(sessionId);
            return res.data;
        } catch (e) {
            this.handleError(e)
        }

    }

    public async getAllGrowingSessions() {
        try {
            const { user } = useUserStore()
            const res = await this.api.getGrowingSessionsByUserId(user.id!);
            return res.data;
        } catch (e) {
            this.handleError(e)
        }

    }


    public async updateGrowingSession(sessionId: string, req: UpdateGrowingSessionRequest) {
        try {
            await this.api.updateGrowingSession(sessionId, req);
        } catch (e) {
            this.handleError(e)
        }

    }

    public async createGrowingSession(req: CreateGrowingSessionRequest) {
        try {
            const store = useUserStore();
            await this.api.createGrowingSession(store.user.id, req);
        } catch (e) {
            this.handleError(e)
        }

    }

    public async deleteGrowingSession(sessionId: string) {
        try {
            await this.api.deleteGrowingSession(sessionId);
        } catch (e) {
            this.handleError(e)
        }

    }

    public async registerWatering(sessionId: string) {
        try {
            await this.api.registerWatering(sessionId);
        } catch (e) {
            this.handleError(e)
        }

    }

}
