<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<template>
    <v-card class="pt-4" variant="text">
        <plant-image-carousel max-height="300" :session="session"/>
        <div class="d-flex justify-space-between align-center pr-2">
            <v-card-title class="pl-4 d-flex align-content-end v-label--clickable" @click="navigateToSessionDiary(session)">
                {{ session.label }}
            </v-card-title>

            <v-btn density="compact" height="25" icon variant="text" @click="showDetails = !showDetails">
                <v-icon>{{ showDetails ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
            </v-btn>
        </div>
        <g-session-card-details class="pt-0 px-3 " :sensor-readings="{} as SensorsReading" :session="session" :show-details="showDetails"/>
    </v-card>
</template>

<script setup lang="ts">
import {GrowingSession, SensorsReading} from '@/api';
import {Props, useViewParamStore} from '@/store/viewParamStore';
import {useRouter} from 'vue-router';
import GSessionCardDetails from './GSessionCardDetails.vue';
import PlantImageCarousel from './PlantImageCarousel.vue';
import {ref} from 'vue';

const router = useRouter();

const props = defineProps({
    session: {
        type: Object as () => GrowingSession,
        required: true,
    },
});

const showDetails = ref(false);

function navigateToSessionDiary(plant: GrowingSession | any) {
    useViewParamStore().setNextProps({growingSession: props.session} as Props);
    router.push({name: 'growing-session-diary'});
}

</script>

<style scoped></style>
