<template>
  <v-dialog v-model="showDeviceInfoDialog" activator="parent" width="auto">
    <v-card class="mx-auto" min-width="90vw">
      <v-toolbar color="primary" dark>
        <v-toolbar-title>Acquire Device</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form @submit.prevent="acquireDevice">
          <v-text-field v-model="deviceId" label="Device ID" required />
          <v-text-field v-model="description" label="Description" required />
          <v-btn color="primary" type="submit">
            Acquire
          </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn block color="primary" @click="showDeviceInfoDialog = false">
          Close Dialog
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { Device } from '@/api';
import { deviceService } from '@/service';
import { useUserStore } from '@/store/userStore';
import { ref } from 'vue';

const { addDevice: loadDevice } = useUserStore();
const showDeviceInfoDialog = ref(false);


const deviceId = ref('');
const description = ref('');

async function acquireDevice() {
  await deviceService.pairDevice(useUserStore().user.id, deviceId.value, description.value);
  loadDevice({ deviceId: deviceId.value, description: description.value } as unknown as Device);
}

</script>
  
<style scoped></style>
