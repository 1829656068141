import { TimelapseApi } from '@/api';
import BaseService from './baseService';

export default class TimeapseService extends BaseService<TimelapseApi> {

    constructor() {
        super(new TimelapseApi());
    }


    public async getTimelapseImages(sessionId: string) {
        console.log('getTimelapseImages', sessionId)
        try {
            const res = await this.api.getTimelapseImages(sessionId);
            return res.data;
        } catch (e) {
            this.handleError(e)
        }

    }

    async saveTimelapseImage(sessionId: string, image: string) {
        try {
            console.log('saveTimelapseImage', sessionId)
            return (await this.api.saveTimelapseImage(sessionId, image)).data;
        } catch (e) {
            this.handleError(e);
        }
    }

}
