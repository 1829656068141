<template>
  <v-container fluid>
    <v-row v-if="sensorStore.growingSessions.length === 0">
      <v-col>
        <!-- Add great looking way to show a message "You're almost there, try to create a Diary"-->
        <v-alert class="mx-auto" color="info" icon="mdi-information" title="Welcome" variant="tonal">
          You're almost there, try to create a Diary! <br>
          Use the <v-icon class="mb-1">
            mdi-shape-square-rounded-plus
          </v-icon> button
        </v-alert>
      </v-col>
    </v-row>
    <v-row no-gutters >
      <v-col v-for="(session, index) in sensorStore.growingSessions" :key="session.id" cols="12" lg="3" md="4" sm="6">
        <session-card class="mx-auto" max-width="450" :session="session" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">

import { growingSessionService } from '@/service';
import { useGrowingSessionStore } from '@/store/sessionsStore';
import { useUserStore } from '@/store/userStore';
import { onMounted, ref } from 'vue';
import SessionCard from './components/GSessionCard.vue';


const sensorStore = useGrowingSessionStore();
const userStore = useUserStore();

let growingSessions = ref(sensorStore.growingSessions);

onMounted(async () => {
  const growingSessions = await growingSessionService.getAllGrowingSessions() ?? [];
  sensorStore.loadSensors(growingSessions);
})

</script>

<style scoped></style>
