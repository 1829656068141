<template>
    <v-stepper v-model="currentStep" hide-actions :non-linear="false">
        <v-stepper-header>
            <v-stepper-item
                :complete="currentStep > 0"
                icon="mdi-network"
            />
            <v-divider/>
        </v-stepper-header>
        <v-stepper-window>
            <wizard-item :can-done="() => !!deviceId && !!deviceDescription" :prev-icon="'mdi-close'"
                         use-close use-done @wizard-completed="completeWizard">
                <v-form ref="form" class="pt-3">
                    <v-text-field v-model="deviceId" aria-required label="Device ID" required variant="outlined"/>
                    <v-text-field v-model="deviceDescription" aria-required label="Device Name" required
                                  variant="outlined"/>
                </v-form>
            </wizard-item>
        </v-stepper-window>
    </v-stepper>
</template>

<script setup lang="ts">
import {deviceService} from '@/service';
import {useUserStore} from '@/store/userStore';
import {ref} from 'vue';
import WizardItem from './components/WizardItem.vue';

const userStore = useUserStore();
const currentStep = ref(0);

const deviceId = ref<string>();
const deviceDescription = ref<string>();


async function completeWizard() {
    var device = await deviceService.pairDevice(userStore.user.id, deviceId.value!, deviceDescription.value!);
    userStore.addDevice(device!);
}


</script>
<style>

</style>
