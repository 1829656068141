/* tslint:disable */
/* eslint-disable */
/**
 * Gea Server
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'town'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'village'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'county'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    'countryCode'?: string;
}
/**
 * 
 * @export
 * @interface CreateGrowingSessionRequest
 */
export interface CreateGrowingSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateGrowingSessionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof CreateGrowingSessionRequest
     */
    'plantSpecies'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGrowingSessionRequest
     */
    'seedingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateGrowingSessionRequest
     */
    'plantImageBase64'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateGrowingSessionRequest
     */
    'substrates': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof CreateGrowingSessionRequest
     */
    'minMoistureValue': number;
    /**
     * 
     * @type {Position}
     * @memberof CreateGrowingSessionRequest
     */
    'position': Position;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof CreateGrowingSessionRequest
     */
    'sensors': Array<Sensor>;
}
/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'description': string;
    /**
     * 
     * @type {DeviceSettings}
     * @memberof Device
     */
    'deviceInfo'?: DeviceSettings;
    /**
     * 
     * @type {Position}
     * @memberof Device
     */
    'position'?: Position;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof Device
     */
    'sensors': Array<Sensor>;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeviceCommandEnum = {
    ForcePublishDeviceSettings: 'FORCE_PUBLISH_DEVICE_SETTINGS',
    ForcePublishSensors: 'FORCE_PUBLISH_SENSORS',
    PausePublisher: 'PAUSE_PUBLISHER',
    StartPublisher: 'START_PUBLISHER',
    ForcePublishCalibration: 'FORCE_PUBLISH_CALIBRATION'
} as const;

export type DeviceCommandEnum = typeof DeviceCommandEnum[keyof typeof DeviceCommandEnum];


/**
 * 
 * @export
 * @interface DeviceSettings
 */
export interface DeviceSettings {
    /**
     * 
     * @type {number}
     * @memberof DeviceSettings
     */
    'loop_WAIT_TIME'?: number;
    /**
     * 
     * @type {number}
     * @memberof DeviceSettings
     */
    'deep_SLEEP_TIME'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeviceSettings
     */
    'wifi_SSID'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeviceSettings
     */
    'mqtt_HOST_IP'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeviceSettings
     */
    'publisher_CAN_PUBLISH'?: boolean;
}
/**
 * 
 * @export
 * @interface GrowingSession
 */
export interface GrowingSession {
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'plantSpecies'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'seedingDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GrowingSession
     */
    'substrates': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GrowingSession
     */
    'minMoistureValue'?: number;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof GrowingSession
     */
    'sensors': Array<Sensor>;
    /**
     * 
     * @type {Position}
     * @memberof GrowingSession
     */
    'position': Position;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof GrowingSession
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface LocationResponse
 */
export interface LocationResponse {
    /**
     * 
     * @type {string}
     * @memberof LocationResponse
     */
    'lat'?: string;
    /**
     * 
     * @type {string}
     * @memberof LocationResponse
     */
    'lon'?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationResponse
     */
    'importance'?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationResponse
     */
    'displayName'?: string;
    /**
     * 
     * @type {Address}
     * @memberof LocationResponse
     */
    'address'?: Address;
}
/**
 * 
 * @export
 * @interface Metrics
 */
export interface Metrics {
    /**
     * 
     * @type {Array<TimeSeriesRecord>}
     * @memberof Metrics
     */
    'timeSeries': Array<TimeSeriesRecord>;
}
/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'id': string;
    /**
     * 
     * @type {NotificationType}
     * @memberof Notification
     */
    'type': NotificationType;
    /**
     * 
     * @type {boolean}
     * @memberof Notification
     */
    'toRead'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'userId': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    'updatedAt': string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Notification
     */
    'params': { [key: string]: string; };
}


/**
 * 
 * @export
 * @enum {string}
 */

export const NotificationType = {
    AcquireImageRequest: 'ACQUIRE_IMAGE_REQUEST',
    SoilMoistureWarning: 'SOIL_MOISTURE_WARNING'
} as const;

export type NotificationType = typeof NotificationType[keyof typeof NotificationType];


/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'room'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'town'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'state'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'latitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'longitude'?: string;
    /**
     * 
     * @type {string}
     * @memberof Position
     */
    'displayName'?: string;
}
/**
 * 
 * @export
 * @interface Sensor
 */
export interface Sensor {
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Sensor
     */
    'deviceId': string;
    /**
     * 
     * @type {SensorType}
     * @memberof Sensor
     */
    'type': SensorType;
    /**
     * 
     * @type {number}
     * @memberof Sensor
     */
    'code': number;
    /**
     * 
     * @type {SensorCalibration}
     * @memberof Sensor
     */
    'calibration': SensorCalibration;
}


/**
 * 
 * @export
 * @interface SensorCalibration
 */
export interface SensorCalibration {
    /**
     * 
     * @type {number}
     * @memberof SensorCalibration
     */
    'minAbs'?: number;
    /**
     * 
     * @type {number}
     * @memberof SensorCalibration
     */
    'maxAbs'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const SensorType = {
    Temperature: 'TEMPERATURE',
    Humidity: 'HUMIDITY',
    SoilMoisture: 'SOIL_MOISTURE',
    LightIntensity: 'LIGHT_INTENSITY',
    Co2: 'CO2',
    Pressure: 'PRESSURE',
    WateringEvent: 'WATERING_EVENT'
} as const;

export type SensorType = typeof SensorType[keyof typeof SensorType];


/**
 * 
 * @export
 * @interface SensorsReading
 */
export interface SensorsReading {
    /**
     * 
     * @type {number}
     * @memberof SensorsReading
     */
    'moisture': number;
    /**
     * 
     * @type {number}
     * @memberof SensorsReading
     */
    'temperature': number;
    /**
     * 
     * @type {number}
     * @memberof SensorsReading
     */
    'humidity': number;
    /**
     * 
     * @type {number}
     * @memberof SensorsReading
     */
    'pressure': number;
    /**
     * 
     * @type {number}
     * @memberof SensorsReading
     */
    'vpd': number;
}
/**
 * 
 * @export
 * @interface SignInRequest
 */
export interface SignInRequest {
    /**
     * 
     * @type {string}
     * @memberof SignInRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignInRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignUpRequest
 */
export interface SignUpRequest {
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpRequest
     */
    'password': string;
}
/**
 * 
 * @export
 * @interface SignUpResponse
 */
export interface SignUpResponse {
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'currentDeviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'lastMessageSentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof SignUpResponse
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface TimeSeriesRecord
 */
export interface TimeSeriesRecord {
    /**
     * 
     * @type {number}
     * @memberof TimeSeriesRecord
     */
    'value'?: number;
    /**
     * 
     * @type {string}
     * @memberof TimeSeriesRecord
     */
    'time': string;
}
/**
 * 
 * @export
 * @interface TimelapseImage
 */
export interface TimelapseImage {
    /**
     * 
     * @type {string}
     * @memberof TimelapseImage
     */
    'dataBase64': string;
    /**
     * 
     * @type {string}
     * @memberof TimelapseImage
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TimelapseImage
     */
    'updatedAt': string;
}
/**
 * 
 * @export
 * @interface UpdateGrowingSessionRequest
 */
export interface UpdateGrowingSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateGrowingSessionRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGrowingSessionRequest
     */
    'plantSpecies'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGrowingSessionRequest
     */
    'seedingDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateGrowingSessionRequest
     */
    'plantImageBase64'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGrowingSessionRequest
     */
    'substrates'?: Array<string>;
    /**
     * 
     * @type {Position}
     * @memberof UpdateGrowingSessionRequest
     */
    'position'?: Position;
    /**
     * 
     * @type {number}
     * @memberof UpdateGrowingSessionRequest
     */
    'minMoistureValue'?: number;
    /**
     * 
     * @type {Array<Sensor>}
     * @memberof UpdateGrowingSessionRequest
     */
    'sensors'?: Array<Sensor>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'currentDeviceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'lastMessageSentAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'updatedAt': string;
}

/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {SensorType} sensorType 
         * @param {CalibrateSensorsCalibrationTypeEnum} calibrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateSensors: async (deviceId: string, sensorType: SensorType, calibrationType: CalibrateSensorsCalibrationTypeEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('calibrateSensors', 'deviceId', deviceId)
            // verify required parameter 'sensorType' is not null or undefined
            assertParamExists('calibrateSensors', 'sensorType', sensorType)
            // verify required parameter 'calibrationType' is not null or undefined
            assertParamExists('calibrateSensors', 'calibrationType', calibrationType)
            const localVarPath = `/api/device/{deviceId}/sensor/calibrate`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sensorType !== undefined) {
                localVarQueryParameter['sensorType'] = sensorType;
            }

            if (calibrationType !== undefined) {
                localVarQueryParameter['calibrationType'] = calibrationType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceById', 'deviceId', deviceId)
            const localVarPath = `/api/device/{deviceId}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceByUserId: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getDeviceByUserId', 'userId', userId)
            const localVarPath = `/api/device/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceSettings: async (deviceId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getDeviceSettings', 'deviceId', deviceId)
            const localVarPath = `/api/device/{deviceId}/settings`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesById: async (requestBody: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('getDevicesById', 'requestBody', requestBody)
            const localVarPath = `/api/device/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} deviceId 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairDevice: async (userId: string, deviceId: string, description: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('pairDevice', 'userId', userId)
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('pairDevice', 'deviceId', deviceId)
            // verify required parameter 'description' is not null or undefined
            assertParamExists('pairDevice', 'description', description)
            const localVarPath = `/api/device/{deviceId}/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)))
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {DeviceCommandEnum} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPausePublisher: async (deviceId: string, command: DeviceCommandEnum, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('startPausePublisher', 'deviceId', deviceId)
            // verify required parameter 'command' is not null or undefined
            assertParamExists('startPausePublisher', 'command', command)
            const localVarPath = `/api/device/{deviceId}/publisher`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (command !== undefined) {
                localVarQueryParameter['command'] = command;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {SensorType} sensorType 
         * @param {CalibrateSensorsCalibrationTypeEnum} calibrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calibrateSensors(deviceId: string, sensorType: SensorType, calibrationType: CalibrateSensorsCalibrationTypeEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calibrateSensors(deviceId, sensorType, calibrationType, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.calibrateSensors']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceById(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceById(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.getDeviceById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceByUserId(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceByUserId(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.getDeviceByUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceSettings(deviceId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeviceSettings>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceSettings(deviceId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.getDeviceSettings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDevicesById(requestBody: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Device>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDevicesById(requestBody, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.getDevicesById']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} deviceId 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pairDevice(userId: string, deviceId: string, description: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Device>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pairDevice(userId, deviceId, description, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.pairDevice']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {DeviceCommandEnum} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startPausePublisher(deviceId: string, command: DeviceCommandEnum, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startPausePublisher(deviceId, command, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DeviceApi.startPausePublisher']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} deviceId 
         * @param {SensorType} sensorType 
         * @param {CalibrateSensorsCalibrationTypeEnum} calibrationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calibrateSensors(deviceId: string, sensorType: SensorType, calibrationType: CalibrateSensorsCalibrationTypeEnum, options?: any): AxiosPromise<void> {
            return localVarFp.calibrateSensors(deviceId, sensorType, calibrationType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceById(deviceId: string, options?: any): AxiosPromise<Device> {
            return localVarFp.getDeviceById(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceByUserId(userId: string, options?: any): AxiosPromise<Array<Device>> {
            return localVarFp.getDeviceByUserId(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceSettings(deviceId: string, options?: any): AxiosPromise<DeviceSettings> {
            return localVarFp.getDeviceSettings(deviceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} requestBody 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDevicesById(requestBody: Array<string>, options?: any): AxiosPromise<Array<Device>> {
            return localVarFp.getDevicesById(requestBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {string} deviceId 
         * @param {string} description 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pairDevice(userId: string, deviceId: string, description: string, options?: any): AxiosPromise<Device> {
            return localVarFp.pairDevice(userId, deviceId, description, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {DeviceCommandEnum} command 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startPausePublisher(deviceId: string, command: DeviceCommandEnum, options?: any): AxiosPromise<void> {
            return localVarFp.startPausePublisher(deviceId, command, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * 
     * @param {string} deviceId 
     * @param {SensorType} sensorType 
     * @param {CalibrateSensorsCalibrationTypeEnum} calibrationType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public calibrateSensors(deviceId: string, sensorType: SensorType, calibrationType: CalibrateSensorsCalibrationTypeEnum, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).calibrateSensors(deviceId, sensorType, calibrationType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceById(deviceId: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceById(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceByUserId(userId: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceByUserId(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceSettings(deviceId: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceSettings(deviceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} requestBody 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDevicesById(requestBody: Array<string>, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDevicesById(requestBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {string} deviceId 
     * @param {string} description 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public pairDevice(userId: string, deviceId: string, description: string, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).pairDevice(userId, deviceId, description, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {DeviceCommandEnum} command 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public startPausePublisher(deviceId: string, command: DeviceCommandEnum, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).startPausePublisher(deviceId, command, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const CalibrateSensorsCalibrationTypeEnum = {
    Min: 'MIN',
    Max: 'MAX'
} as const;
export type CalibrateSensorsCalibrationTypeEnum = typeof CalibrateSensorsCalibrationTypeEnum[keyof typeof CalibrateSensorsCalibrationTypeEnum];


/**
 * GeolocationApi - axios parameter creator
 * @export
 */
export const GeolocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverse: async (latitude: number, longitude: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'latitude' is not null or undefined
            assertParamExists('reverse', 'latitude', latitude)
            // verify required parameter 'longitude' is not null or undefined
            assertParamExists('reverse', 'longitude', longitude)
            const localVarPath = `/api/geo/reverse`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (latitude !== undefined) {
                localVarQueryParameter['latitude'] = latitude;
            }

            if (longitude !== undefined) {
                localVarQueryParameter['longitude'] = longitude;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('search', 'query', query)
            const localVarPath = `/api/geo/search/{query}`
                .replace(`{${"query"}}`, encodeURIComponent(String(query)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeolocationApi - functional programming interface
 * @export
 */
export const GeolocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeolocationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reverse(latitude: number, longitude: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reverse(latitude, longitude, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeolocationApi.reverse']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GeolocationApi.search']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GeolocationApi - factory interface
 * @export
 */
export const GeolocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeolocationApiFp(configuration)
    return {
        /**
         * 
         * @param {number} latitude 
         * @param {number} longitude 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reverse(latitude: number, longitude: number, options?: any): AxiosPromise<LocationResponse> {
            return localVarFp.reverse(latitude, longitude, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} query 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query: string, options?: any): AxiosPromise<Array<LocationResponse>> {
            return localVarFp.search(query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeolocationApi - object-oriented interface
 * @export
 * @class GeolocationApi
 * @extends {BaseAPI}
 */
export class GeolocationApi extends BaseAPI {
    /**
     * 
     * @param {number} latitude 
     * @param {number} longitude 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationApi
     */
    public reverse(latitude: number, longitude: number, options?: AxiosRequestConfig) {
        return GeolocationApiFp(this.configuration).reverse(latitude, longitude, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} query 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeolocationApi
     */
    public search(query: string, options?: AxiosRequestConfig) {
        return GeolocationApiFp(this.configuration).search(query, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GrowingSessionApi - axios parameter creator
 * @export
 */
export const GrowingSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {CreateGrowingSessionRequest} createGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGrowingSession: async (userId: string, createGrowingSessionRequest: CreateGrowingSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('createGrowingSession', 'userId', userId)
            // verify required parameter 'createGrowingSessionRequest' is not null or undefined
            assertParamExists('createGrowingSession', 'createGrowingSessionRequest', createGrowingSessionRequest)
            const localVarPath = `/api/growing-session/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createGrowingSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGrowingSession: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('deleteGrowingSession', 'sessionId', sessionId)
            const localVarPath = `/api/growing-session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowingSession: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getGrowingSession', 'sessionId', sessionId)
            const localVarPath = `/api/growing-session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowingSessionsByUserId: async (userid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userid' is not null or undefined
            assertParamExists('getGrowingSessionsByUserId', 'userid', userid)
            const localVarPath = `/api/growing-session/user/{userid}`
                .replace(`{${"userid"}}`, encodeURIComponent(String(userid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWatering: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('registerWatering', 'sessionId', sessionId)
            const localVarPath = `/api/growing-session/{sessionId}/registerWatering`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {UpdateGrowingSessionRequest} updateGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGrowingSession: async (sessionId: string, updateGrowingSessionRequest: UpdateGrowingSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('updateGrowingSession', 'sessionId', sessionId)
            // verify required parameter 'updateGrowingSessionRequest' is not null or undefined
            assertParamExists('updateGrowingSession', 'updateGrowingSessionRequest', updateGrowingSessionRequest)
            const localVarPath = `/api/growing-session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGrowingSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GrowingSessionApi - functional programming interface
 * @export
 */
export const GrowingSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GrowingSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {CreateGrowingSessionRequest} createGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGrowingSession(userId: string, createGrowingSessionRequest: CreateGrowingSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrowingSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGrowingSession(userId, createGrowingSessionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.createGrowingSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGrowingSession(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGrowingSession(sessionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.deleteGrowingSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrowingSession(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GrowingSession>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrowingSession(sessionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.getGrowingSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGrowingSessionsByUserId(userid: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GrowingSession>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGrowingSessionsByUserId(userid, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.getGrowingSessionsByUserId']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async registerWatering(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.registerWatering(sessionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.registerWatering']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {UpdateGrowingSessionRequest} updateGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGrowingSession(sessionId: string, updateGrowingSessionRequest: UpdateGrowingSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGrowingSession(sessionId, updateGrowingSessionRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['GrowingSessionApi.updateGrowingSession']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * GrowingSessionApi - factory interface
 * @export
 */
export const GrowingSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GrowingSessionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {CreateGrowingSessionRequest} createGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGrowingSession(userId: string, createGrowingSessionRequest: CreateGrowingSessionRequest, options?: any): AxiosPromise<GrowingSession> {
            return localVarFp.createGrowingSession(userId, createGrowingSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGrowingSession(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGrowingSession(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowingSession(sessionId: string, options?: any): AxiosPromise<GrowingSession> {
            return localVarFp.getGrowingSession(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userid 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGrowingSessionsByUserId(userid: string, options?: any): AxiosPromise<Array<GrowingSession>> {
            return localVarFp.getGrowingSessionsByUserId(userid, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerWatering(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.registerWatering(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {UpdateGrowingSessionRequest} updateGrowingSessionRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGrowingSession(sessionId: string, updateGrowingSessionRequest: UpdateGrowingSessionRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateGrowingSession(sessionId, updateGrowingSessionRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GrowingSessionApi - object-oriented interface
 * @export
 * @class GrowingSessionApi
 * @extends {BaseAPI}
 */
export class GrowingSessionApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {CreateGrowingSessionRequest} createGrowingSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public createGrowingSession(userId: string, createGrowingSessionRequest: CreateGrowingSessionRequest, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).createGrowingSession(userId, createGrowingSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public deleteGrowingSession(sessionId: string, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).deleteGrowingSession(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public getGrowingSession(sessionId: string, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).getGrowingSession(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userid 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public getGrowingSessionsByUserId(userid: string, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).getGrowingSessionsByUserId(userid, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public registerWatering(sessionId: string, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).registerWatering(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {UpdateGrowingSessionRequest} updateGrowingSessionRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GrowingSessionApi
     */
    public updateGrowingSession(sessionId: string, updateGrowingSessionRequest: UpdateGrowingSessionRequest, options?: AxiosRequestConfig) {
        return GrowingSessionApiFp(this.configuration).updateGrowingSession(sessionId, updateGrowingSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * MetricsApi - axios parameter creator
 * @export
 */
export const MetricsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSensorReadings: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getLastSensorReadings', 'sessionId', sessionId)
            const localVarPath = `/api/metrics/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {number} sensorCode 
         * @param {SensorType} sensorType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} resolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics: async (deviceId: string, sensorCode: number, sensorType: SensorType, dateFrom: string, dateTo: string, resolution: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'deviceId' is not null or undefined
            assertParamExists('getMetrics', 'deviceId', deviceId)
            // verify required parameter 'sensorCode' is not null or undefined
            assertParamExists('getMetrics', 'sensorCode', sensorCode)
            // verify required parameter 'sensorType' is not null or undefined
            assertParamExists('getMetrics', 'sensorType', sensorType)
            // verify required parameter 'dateFrom' is not null or undefined
            assertParamExists('getMetrics', 'dateFrom', dateFrom)
            // verify required parameter 'dateTo' is not null or undefined
            assertParamExists('getMetrics', 'dateTo', dateTo)
            // verify required parameter 'resolution' is not null or undefined
            assertParamExists('getMetrics', 'resolution', resolution)
            const localVarPath = `/api/metrics/device/{deviceId}/sensor/{sensorType}/{sensorCode}`
                .replace(`{${"deviceId"}}`, encodeURIComponent(String(deviceId)))
                .replace(`{${"sensorCode"}}`, encodeURIComponent(String(sensorCode)))
                .replace(`{${"sensorType"}}`, encodeURIComponent(String(sensorType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dateFrom !== undefined) {
                localVarQueryParameter['dateFrom'] = (dateFrom as any instanceof Date) ?
                    (dateFrom as any).toISOString() :
                    dateFrom;
            }

            if (dateTo !== undefined) {
                localVarQueryParameter['dateTo'] = (dateTo as any instanceof Date) ?
                    (dateTo as any).toISOString() :
                    dateTo;
            }

            if (resolution !== undefined) {
                localVarQueryParameter['resolution'] = resolution;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MetricsApi - functional programming interface
 * @export
 */
export const MetricsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MetricsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLastSensorReadings(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SensorsReading>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLastSensorReadings(sessionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MetricsApi.getLastSensorReadings']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {number} sensorCode 
         * @param {SensorType} sensorType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} resolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMetrics(deviceId: string, sensorCode: number, sensorType: SensorType, dateFrom: string, dateTo: string, resolution: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Metrics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMetrics(deviceId, sensorCode, sensorType, dateFrom, dateTo, resolution, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['MetricsApi.getMetrics']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * MetricsApi - factory interface
 * @export
 */
export const MetricsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MetricsApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastSensorReadings(sessionId: string, options?: any): AxiosPromise<SensorsReading> {
            return localVarFp.getLastSensorReadings(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deviceId 
         * @param {number} sensorCode 
         * @param {SensorType} sensorType 
         * @param {string} dateFrom 
         * @param {string} dateTo 
         * @param {string} resolution 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMetrics(deviceId: string, sensorCode: number, sensorType: SensorType, dateFrom: string, dateTo: string, resolution: string, options?: any): AxiosPromise<Metrics> {
            return localVarFp.getMetrics(deviceId, sensorCode, sensorType, dateFrom, dateTo, resolution, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MetricsApi - object-oriented interface
 * @export
 * @class MetricsApi
 * @extends {BaseAPI}
 */
export class MetricsApi extends BaseAPI {
    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getLastSensorReadings(sessionId: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getLastSensorReadings(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deviceId 
     * @param {number} sensorCode 
     * @param {SensorType} sensorType 
     * @param {string} dateFrom 
     * @param {string} dateTo 
     * @param {string} resolution 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MetricsApi
     */
    public getMetrics(deviceId: string, sensorCode: number, sensorType: SensorType, dateFrom: string, dateTo: string, resolution: string, options?: AxiosRequestConfig) {
        return MetricsApiFp(this.configuration).getMetrics(deviceId, sensorCode, sensorType, dateFrom, dateTo, resolution, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * NotificationApi - axios parameter creator
 * @export
 */
export const NotificationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByUser: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getNotificationsByUser', 'userId', userId)
            const localVarPath = `/api/notification/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsRead: async (notificationIds: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'notificationIds' is not null or undefined
            assertParamExists('markNotificationsAsRead', 'notificationIds', notificationIds)
            const localVarPath = `/api/notification/read`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (notificationIds) {
                localVarQueryParameter['notificationIds'] = notificationIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationApi - functional programming interface
 * @export
 */
export const NotificationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNotificationsByUser(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Notification>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNotificationsByUser(userId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationApi.getNotificationsByUser']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markNotificationsAsRead(notificationIds: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markNotificationsAsRead(notificationIds, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['NotificationApi.markNotificationsAsRead']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * NotificationApi - factory interface
 * @export
 */
export const NotificationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationApiFp(configuration)
    return {
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByUser(userId: string, options?: any): AxiosPromise<Array<Notification>> {
            return localVarFp.getNotificationsByUser(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string>} notificationIds 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markNotificationsAsRead(notificationIds: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.markNotificationsAsRead(notificationIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationApi - object-oriented interface
 * @export
 * @class NotificationApi
 * @extends {BaseAPI}
 */
export class NotificationApi extends BaseAPI {
    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public getNotificationsByUser(userId: string, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).getNotificationsByUser(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string>} notificationIds 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationApi
     */
    public markNotificationsAsRead(notificationIds: Array<string>, options?: AxiosRequestConfig) {
        return NotificationApiFp(this.configuration).markNotificationsAsRead(notificationIds, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PlantSpeciesApi - axios parameter creator
 * @export
 */
export const PlantSpeciesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutocomplete: async (value: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'value' is not null or undefined
            assertParamExists('getAutocomplete', 'value', value)
            const localVarPath = `/api/plant-species/autocomplete`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (value !== undefined) {
                localVarQueryParameter['value'] = value;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PlantSpeciesApi - functional programming interface
 * @export
 */
export const PlantSpeciesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PlantSpeciesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAutocomplete(value: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAutocomplete(value, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['PlantSpeciesApi.getAutocomplete']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * PlantSpeciesApi - factory interface
 * @export
 */
export const PlantSpeciesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PlantSpeciesApiFp(configuration)
    return {
        /**
         * 
         * @param {string} value 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutocomplete(value: string, options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getAutocomplete(value, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PlantSpeciesApi - object-oriented interface
 * @export
 * @class PlantSpeciesApi
 * @extends {BaseAPI}
 */
export class PlantSpeciesApi extends BaseAPI {
    /**
     * 
     * @param {string} value 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PlantSpeciesApi
     */
    public getAutocomplete(value: string, options?: AxiosRequestConfig) {
        return PlantSpeciesApiFp(this.configuration).getAutocomplete(value, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TimelapseApi - axios parameter creator
 * @export
 */
export const TimelapseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimelapseImages: async (sessionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('getTimelapseImages', 'sessionId', sessionId)
            const localVarPath = `/api/timelapse/{sessionId}/images`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTimelapseImage: async (sessionId: string, body: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('saveTimelapseImage', 'sessionId', sessionId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('saveTimelapseImage', 'body', body)
            const localVarPath = `/api/timelapse/{sessionId}/image`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimelapseApi - functional programming interface
 * @export
 */
export const TimelapseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TimelapseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTimelapseImages(sessionId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TimelapseImage>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTimelapseImages(sessionId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimelapseApi.getTimelapseImages']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async saveTimelapseImage(sessionId: string, body: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TimelapseImage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.saveTimelapseImage(sessionId, body, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['TimelapseApi.saveTimelapseImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * TimelapseApi - factory interface
 * @export
 */
export const TimelapseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TimelapseApiFp(configuration)
    return {
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTimelapseImages(sessionId: string, options?: any): AxiosPromise<Array<TimelapseImage>> {
            return localVarFp.getTimelapseImages(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {string} body 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTimelapseImage(sessionId: string, body: string, options?: any): AxiosPromise<TimelapseImage> {
            return localVarFp.saveTimelapseImage(sessionId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TimelapseApi - object-oriented interface
 * @export
 * @class TimelapseApi
 * @extends {BaseAPI}
 */
export class TimelapseApi extends BaseAPI {
    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelapseApi
     */
    public getTimelapseImages(sessionId: string, options?: AxiosRequestConfig) {
        return TimelapseApiFp(this.configuration).getTimelapseImages(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {string} body 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimelapseApi
     */
    public saveTimelapseImage(sessionId: string, body: string, options?: AxiosRequestConfig) {
        return TimelapseApiFp(this.configuration).saveTimelapseImage(sessionId, body, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SignInRequest} signInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn: async (signInRequest: SignInRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signInRequest' is not null or undefined
            assertParamExists('signIn', 'signInRequest', signInRequest)
            const localVarPath = `/api/user/sign-in`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signInRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp: async (signUpRequest: SignUpRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'signUpRequest' is not null or undefined
            assertParamExists('signUp', 'signUpRequest', signUpRequest)
            const localVarPath = `/api/user/sign-up`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(signUpRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SignInRequest} signInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signIn(signInRequest: SignInRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signIn(signInRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.signIn']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignUpResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpRequest, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['UserApi.signUp']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {SignInRequest} signInRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signIn(signInRequest: SignInRequest, options?: any): AxiosPromise<User> {
            return localVarFp.signIn(signInRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SignUpRequest} signUpRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        signUp(signUpRequest: SignUpRequest, options?: any): AxiosPromise<SignUpResponse> {
            return localVarFp.signUp(signUpRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {SignInRequest} signInRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signIn(signInRequest: SignInRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).signIn(signInRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SignUpRequest} signUpRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public signUp(signUpRequest: SignUpRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).signUp(signUpRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



