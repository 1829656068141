<template>
  <v-card class="mx-auto pb-2">
    <v-card-item>
      <template v-if="separateCharts && chartDataList">
        <v-row v-for="(data, index) in chartDataList" :key="index">
          <v-col class="pl-3 pr-1" cols="9" :md="11" :sm="10">
            <!-- @vue-ignore -->
            <Line :data="(data as ChartData<'line', Array<any>, unknown>)" :options="chartOptions" />
          </v-col>
          <v-spacer />
          <v-col class="px-1 pt-3" cols="3" :md="1" :sm="2">
            <v-label class="text-caption">
              | Min: {{ Math.min(...data.datasets
                .flatMap(d => d.data as unknown as number)
                .filter(n => !!n)).toFixed(2)
              }}
            </v-label>
            <v-divider />
            <v-label class="text-caption">
              | Max: {{ Math.max(...data.datasets
                .flatMap(d => d.data as unknown as number)
                .filter(n => !!n)).toFixed(2)
              }}
            </v-label>
            <v-divider />
            <v-label class="text-caption">
              | First: {{ data.datasets
                .flatMap(d => d.data as unknown as number)
                .filter(n => !!n)[0]?.toFixed(2) }}
            </v-label>
            <v-divider />
            <v-label class="text-caption">
              | Last: {{ data.datasets
                .flatMap(d => d.data as unknown as number)
                .pop()?.toFixed(2) }}
            </v-label>
            <v-divider />
            <v-label class="text-caption">
              | Avg: {{ data.datasets
                .flatMap(d => d.data as unknown as number)
                .filter(n => !!n)
                .reduce((a, e, i) => (a * i + e) / (i + 1), 0)?.toFixed(2) }}
            </v-label>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row>
          <v-col cols="12">
            <!-- @vue-ignore -->
            <Line v-if="chartData" :data="(chartData as ChartData<'line', Array<any>, unknown>)"
              :options="(chartOptions as any)" />
          </v-col>
        </v-row>
      </template>
    </v-card-item>
  </v-card>
</template>
  
<script setup lang="ts">

import { GrowingSession, SensorType, TimeSeriesRecord } from '@/api';
import { metricsService } from '@/service';
import { useMetricsStore } from '@/store/metricsStore';
import { CategoryScale, ChartData, Chart as ChartJS, ChartOptions, Colors, Legend, LineElement, LinearScale, PointElement, Title, Tooltip } from 'chart.js';
import { format, subDays, subHours, subMinutes, subMonths } from 'date-fns';
import { onMounted, ref, watch } from 'vue';
import { Line } from 'vue-chartjs';


const metricsStore = useMetricsStore();

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Colors);


const props = defineProps({
  session: {
    type: Object as () => GrowingSession,
    required: true,
  },
  metrics: {
    type: Array<{ sensorType: SensorType, color: string }>,
    required: true
  },
  separateCharts: {
    type: Boolean,
    required: false,
  }
});

const params = ref({
  dateFrom: metricsStore.currentDateFrom,
  dateTo: new Date().toISOString(),
  resolution: metricsStore.currentResolution,
})

let chartData = ref<ChartData>();
let chartDataList = ref<Array<ChartData>>();
let timeSeries = ref<Map<String, TimeSeriesRecord[]>>()

const chartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      align: 'center',
    }
  }
} as ChartOptions

watch(
  () => [props.session, params.value.resolution, params.value.dateFrom, params.value.dateTo],
  async () => {
    if (props.session.sensors.length > 0) {
      console.log('Watcher plant, resolution, dateFrom: update chartData')
      await fetchMetrics();
      try {
        createChartData();
      } catch (e) {
        console.error(e);
      }
    }
  }
);

watch(
  () => [metricsStore.currentTimeRange, metricsStore.currentResolution],
  () => {
    console.log(`Watcher timeRange: ${metricsStore.currentTimeRange}`);
    const value = Number(metricsStore.currentTimeRange.slice(0, -1));
    const range = metricsStore.currentTimeRange.slice(-1);
    switch (range) {
      case 'm':
        metricsStore.currentDateFormat = 'HH:mm';
        params.value.dateFrom = subMinutes(new Date(), value).toISOString();
        break;
      case 'h':
        metricsStore.currentDateFormat = 'HH:mm';
        params.value.dateFrom = subHours(new Date(), value).toISOString();
        break;
      case 'd':
        metricsStore.currentDateFormat = 'dd.MM HH:mm';
        params.value.dateFrom = subDays(new Date(), value).toISOString();
        break;
      case 'M':
        metricsStore.currentDateFormat = 'dd.MM';
        params.value.dateFrom = subMonths(new Date(), value).toISOString();
        break;
    }
    metricsStore.currentDateFrom = params.value.dateFrom;

    const allowedResolutions = metricsStore.timeRanges[metricsStore.currentTimeRange].resolutions;
    if (!allowedResolutions.includes(metricsStore.currentResolution)) {
      metricsStore.currentResolution = allowedResolutions[0];
    }
    params.value.resolution = metricsStore.currentResolution;
    params.value.dateTo = new Date().toISOString();
  }
);

function createChartData() {
  const chartDataUpdate: ChartData = {
    labels: [],
    datasets: []
  };
  const chartDataListUpdate: Array<ChartData> = [];
  for (let { sensorType: name, color } of props.metrics) {
    console.log(`Creating chartData for metric ${name} of ${props.metrics} with params ${JSON.stringify(params.value)}`);
    const timeSerie = timeSeries.value?.get(name);
    const labels = timeSerie?.map((e) => format(new Date(e.time!), metricsStore.currentDateFormat));
    const datasets = {
      label: name,
      data: timeSerie?.map((e) => e.value) ?? [],
      borderColor: color,
      backgroundColor: color,
    };
    chartDataUpdate.labels = labels;
    chartDataUpdate.datasets.push((datasets ?? null) as any);
    chartDataListUpdate.push({
      labels,
      datasets: [(datasets ?? null) as any]
    });

  }
  chartData.value = chartDataUpdate;
  chartDataList.value = chartDataListUpdate;
}

async function fetchMetrics() {

  timeSeries.value = new Map();
  const session = props.session;

  for (let { sensorType } of props.metrics) {
    console.log(`Fetching metric ${sensorType} of ${props.metrics} with params ${JSON.stringify(params.value)}`);
    var sensor = session.sensors.find(s => s.type.toLowerCase() === sensorType.toLowerCase())!;
    const timeSeriesRes = await metricsService.getMetrics(sensorType, sensor.deviceId, sensor.code, params.value);

    if (!timeSeriesRes) {
      continue;
    }
    timeSeries.value.set(sensorType, timeSeriesRes);

  }
}

onMounted(async () => {
  if (props.session.sensors.length > 0) {
    await fetchMetrics();
    createChartData();
  }
});


</script>

<style scoped></style>
  