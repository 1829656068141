<!-- eslint-disable @typescript-eslint/no-unused-vars -->
<template>
    <v-layout class="px-3 pb-1" style="position: relative">
        <div id="controls" class="position-absolute mr-4 mt-1 d-flex flex-column rounded-pill"
             style="background-color: rgba(255, 255, 255, 0.2); z-index: 1; right: 0;">
            <v-btn icon style="z-index: 2;  opacity: 0.75;" variant="plain" @click="navigateToSessionDiary(session)">
                <v-icon size="25">
                    mdi-book-open-outline
                </v-icon>
            </v-btn>
            <v-btn icon style="z-index: 2;  opacity: 0.75;" variant="text">
                <v-icon size="25">
                    mdi-camera-outline
                </v-icon>
                <v-dialog v-model="showDialog" activator="parent">
                    <upload-image-card max-width="90vw" :session="session" @image-acquired="handleImageAcquired"/>
                </v-dialog>
            </v-btn>
            <v-btn icon style="z-index: 2;  opacity: 0.75;" variant="plain" @click="registerWatering(session)">
                <v-icon size="25">
                    mdi-watering-can-outline
                </v-icon>
            </v-btn>
        </div>


        <v-skeleton-loader v-if="!timelapse || timelapse.length === 0" class="elevation-1 rounded" height="298" loading
                           type="image" width="100%"/>
        <v-img v-if="!timelapse || timelapse.length === 0" class="elevation-1 rounded" cover eager height="298"
               :src="defaultImage" width="100%"/>
        <v-carousel v-if="timelapse && timelapse.length > 0" v-model="carouseIndex" class="elevation-1 rounded"
                    height="298" hide-delimiter-background :show-arrows="false" width="100%">
            <v-carousel-item v-for="(image, i) in timelapse" :key="image.name" eager>
                <v-img cover eager height="100%" :src="image.dataBase64" width="100%"/>
            </v-carousel-item>
        </v-carousel>
    </v-layout>
</template>

<script setup lang="ts">
import {GrowingSession, TimelapseImage} from '@/api';
import defaultImage from '@/assets/plant-image-placeholder.jpg'; // => or relative path
import {useTimelapseStore} from '@/store/timelapseStore';
import UploadImageCard from '@/views/components/AcquireImageCard.vue';
import {onMounted, ref} from 'vue';
import {VCarousel} from 'vuetify/lib/components/index.mjs';
import {Props, useViewParamStore} from '@/store/viewParamStore';
import router from '@/plugins/router';
import {growingSessionService} from '@/service';


const props = defineProps({
    session: {
        type: Object as () => GrowingSession,
        required: true,
    },
});

const timelapse = ref<Array<TimelapseImage>>();
const carouseIndex = ref(0);
const showDialog = ref(false);

async function handleImageAcquired(image: string) {
    showDialog.value = false;
    const timelapseStore = useTimelapseStore();
    await timelapseStore.addTimelapseImage(props.session.id, image!);
}

function navigateToSessionDiary(plant: GrowingSession | any) {
    useViewParamStore().setNextProps({growingSession: props.session} as Props);
    router.push({name: 'growing-session-diary'});
}

function registerWatering(plant: GrowingSession | any) {
    if (plant) {
        growingSessionService.registerWatering(plant.id)
    }
}

onMounted(async () => {
    const timelapseStore = useTimelapseStore();
    timelapse.value = await timelapseStore.getTimelapse(props.session.id);
    //carouseIndex.value = carouseIndex.value + 1;
    carouseIndex.value = (timelapse.value?.length ?? 1) - 1

})
</script>

<style>
.v-skeleton-loader__image {
    height: 100%;
}
</style>
