import { subDays } from 'date-fns';
import { defineStore } from 'pinia';

interface ITimeRanges {
    [key: string]: {
        resolutions: Array<string>
    }
}
export const useMetricsStore = defineStore('metricsStore', {
    state: () => ({
        currentTimeRange: '1d',
        currentResolution: '30m',
        currentDateFormat: 'HH:mm',
        currentDateFrom: subDays(Date.now(), 1).toISOString(),
        timeRanges: {
            '15m': {
                resolutions: ['1m', '5m']
            },
            '30m': {
                resolutions: ['5m', '15m']
            },
            '1h': {
                resolutions: ['5m', '15m']
            },
            '6h': {
                resolutions: ['15m', '30m', '1h']
            },
            '1d': {
                resolutions: ['30m', '1h', '4h']
            },
            '3d': {
                resolutions: ['4h', '6h', '1d']
            },
            '7d': {
                resolutions: ['6h', '1d', '3d']
            },
            '14d': {
                resolutions: ['1d', '3d', '7d']
            },
            '1M': {
                resolutions: ['3d', '7d', '14d',]
            },
            '3M': {
                resolutions: ['3d', '7d', '14d']
            },
            '6M': {
                resolutions: ['7d', '14d', '21d']
            },
            '12M': {
                resolutions: ['7d', '14d', '21d']
            }
        } as ITimeRanges
    }),
    getters: {

    },
    actions: {
        setCurrentTimeRangeAndResolution(timeRange: string, resolution: string) {
            this.currentTimeRange = timeRange;

            const allowedResolutions = this.timeRanges[timeRange].resolutions;
            if (!allowedResolutions.includes(resolution)) {
                this.currentResolution = allowedResolutions[0];
            } else {
                this.currentResolution = resolution;
            }
        }
    },
    persist: true

})