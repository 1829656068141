import { PlantSpeciesApi } from '@/api';
import BaseService from './baseService';

export default class PlantSpeciesService extends BaseService<PlantSpeciesApi> {

    constructor() {
        super(new PlantSpeciesApi());
    }


    public async getAutocomplete(plantSpeciesName: string) {
        console.log('getAutocomplete', plantSpeciesName)
        try {
            const res = await this.api.getAutocomplete(plantSpeciesName);
            return res.data!;
        } catch (e) {
            this.handleError(e)
        }

    }

}
