import { TimelapseImage } from '@/api';
import { timelapseService } from '@/service';
import { defineStore } from 'pinia';


export interface Timelapses {
    [key: string]: Array<TimelapseImage>
}

export const useTimelapseStore = defineStore('timelapseStore', {
    state: () => ({
        sessionId2Timelapse: {} as Timelapses,
    }),
    getters: {

    },
    actions: {
        async getTimelapse(sessionId: string) {
            console.log('getTimelapse', sessionId)
            if (!this.sessionId2Timelapse[sessionId]) {
                const timelapse = await timelapseService.getTimelapseImages(sessionId);
                this.sessionId2Timelapse[sessionId] = timelapse ?? [];
                return timelapse;
            }
            return this.sessionId2Timelapse[sessionId];
        },
        async addTimelapseImage(sessionId: string, image: string) {
            console.log('addTimelapseImage', sessionId)
            const timelapseImage = await timelapseService.saveTimelapseImage(sessionId, image);
            this.addTimelapse(sessionId, timelapseImage!);

        },
        addTimelapse(sessionId: string, timelapseImage: TimelapseImage) {
            console.log('addTimelapse', sessionId, timelapseImage.name)
            const timelapse = this.sessionId2Timelapse[sessionId];
            timelapse?.unshift(timelapseImage);
            this.sessionId2Timelapse[sessionId] = timelapse;
        }
    },
    persist: false

})
